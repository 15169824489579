import useTranslation from '@/i18n'
import React from 'react'

interface Props {
  status: string
}

function Status(props: Props) {
  const { t } = useTranslation()
  const { status } = props

  if (status === 'beta') {
    return (
      <div className="bg-green-200 text-green-900 rounded-xl text-center px-4">
        {t('beta')}
      </div>
    )
  } else if (status === 'transferApplying') {
    return (
      <div className="bg-purple-200 border  text-purple-900 rounded-xl  text-center px-4">
        {t('Applying')}
      </div>
    )
  } else if (status === 'refusedTransfer') {
    return (
      <div className="bg-red-500 border  text-red-100 rounded-xl  text-center px-4">
        {t('Refused')}
      </div>
    )
  } else if (status === 'subscription') {
    return (
      <div className="bg-blue-200 text-blue-900 rounded-xl text-center px-4">
        {t('Subscription')}
      </div>
    )
  }

  return <div></div>
}

export default Status
