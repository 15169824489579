import { axios, getAuthorization } from '@/api/axios'
import { withRefreshToken } from '@/auth'
import { errConverter } from '../error'
import { DomainParams } from '@/types'

const getDomainList = (): Promise<DomainParams[]> =>
  new Promise<DomainParams[]>((resolve, reject) =>
    axios
      .get<DomainParams[]>(`/domain-list`, {
        headers: getAuthorization(),
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(errConverter(err))
      })
  )

const get = withRefreshToken(getDomainList)

export { get }
