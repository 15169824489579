import React from 'react'
import GeneralJp from '@/components/DomainTransfer/GenralJp'
import { ApiContactGtldParams } from '@/types'

interface Props {
  domainName: string
  contact: ApiContactGtldParams
  reload: () => Promise<void>
}

function ReapplyEditor(props: Props) {
  const { domainName, contact } = props

  return (
    <div className="md:w-108">
      <GeneralJp
        domainName={domainName}
        contact={contact}
        reload={props.reload}
      />
    </div>
  )
}

export default ReapplyEditor
