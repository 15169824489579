import React from 'react'
import { DomainParams } from '@/types'
import { isValidDomain } from '@/utils'

type Props = {
  domain: DomainParams
}

function DomainName({ domain }: Props) {
  if (!isValidDomain(domain.status)) {
    return (
      <a className="break-words text-sm text-gray-900 font-medium">
        {domain.domain_name}
      </a>
    )
  }

  return (
    <a
      href={`/domain-list/config?id=${domain.id}`}
      className="break-words text-sm text-blue-500 font-medium hover:text-blue-400"
    >
      {domain.domain_name}
    </a>
  )
}

export default DomainName
