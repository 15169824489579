import React, { useEffect } from 'react'
import Table from '@/components/Table'
import { get } from '@/api/domain-list'
import Domain from '@/components/DomainList/DomainData'
import Header from '@/components/DomainList/Header'
import { DomainParams } from '@/types'
import { useErr, useProgress, useSafeState, useWaitSignIn } from '@/hooks'
import Reload from '@/components/Parts/Reload'
import useTranslation from '@/i18n'
import ButtonRounded from '@/components/Parts/ButtonRounded'
import { navigate } from 'gatsby'

function DomainList() {
  const [domains, setDomains] = useSafeState<DomainParams[]>([])
  const [notFound, setNotFound] = useSafeState<boolean>(false)
  const { start, stop } = useProgress()
  const { setErr } = useErr()
  const waitAuth = useWaitSignIn()
  const { t } = useTranslation()

  useEffect(() => {
    waitAuth(load)

    return () => {
      stop()
    }
  }, [])

  const reload = () =>
    new Promise<void>((resolve, reject) => {
      get()
        .then((res) => {
          setDomains(res)
          setNotFound(res.length === 0)
          resolve()
        })
        .catch((err) => {
          setErr(err.message, err.status)
          reject()
        })
    })

  const load = () => {
    start(0)
    reload()
      .then(() => stop())
      .catch(() => stop())
  }

  return (
    <>
      <div className="text-right pt-2 pb-4">
        <ButtonRounded
          name="Domain transfer"
          onClick={() => navigate('/domain-transfer')}
        />
      </div>
      <Reload load={load} />
      <Table
        header={() => <Header />}
        listData={domains}
        list={(data, index) => (
          <Domain domain={data} key={index} reload={reload} />
        )}
      />
      {notFound && (
        <p className="text-center text-2xl text-gray-500 mt-2">
          {t('Domain not found')}
        </p>
      )}
    </>
  )
}

export default DomainList
