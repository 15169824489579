import { axios, getAuthorization } from '@/api/axios'
import { withRefreshToken } from '@/auth'
import { errConverter } from '../error'
import { ApiContactGtldParams } from '@/types'

const getReApplyRegistrantTransfer = (
  domain_name?: string
): Promise<ApiContactGtldParams> =>
  new Promise<ApiContactGtldParams>((resolve, reject) =>
    axios
      .get<ApiContactGtldParams>(`/general-jp/re-apply-registrant-transfer`, {
        headers: getAuthorization(),
        params: {
          domain_name,
        },
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(errConverter(err))
      })
  )

const get = withRefreshToken(getReApplyRegistrantTransfer)

export { get }
