import React from 'react'
import { DomainParams } from '@/types'
import useTranslation from '@/i18n'
import Status from './Status'
import DomainName from './DomainName'
import ReApplyTransfer from './ReApplyTransfer'

type Props = {
  domain: DomainParams
  reload: () => Promise<void>
}

const Domain = ({ domain, reload }: Props) => {
  const { d } = useTranslation()

  return (
    <tr>
      <td className="px-6 py-4 ">
        <DomainName domain={domain} />
      </td>

      <td className="py-4 ">
        <Status status={domain.status} />
      </td>

      <td className="px-6 py-4">{domain.memo}</td>

      <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
        {d('ymdhms', new Date(domain.expired_at))}
      </td>

      <td>
        <ReApplyTransfer
          isApply={domain.status === 'refusedTransfer'}
          domainName={domain.domain_name}
          reload={reload}
        />
      </td>
    </tr>
  )
}

export default Domain
