import React from 'react'
import ButtonRounded from '../Parts/ButtonRounded'
import { get } from '@/api/gneneral-jp/re-apply-registrant-transfer'
import { useModal, useProgress, useRightBar } from '@/hooks'
import GeneralJp from '@/components/DomainTransfer/GenralJp'
import ReapplyEditor from './ReapplyEditor'

interface Props {
  isApply: boolean
  domainName: string
  reload: () => Promise<void>
}

function ReApplyTransfer(props: Props) {
  const { isApply, domainName } = props
  const { start, stop } = useProgress()
  const { openRightBar } = useRightBar()
  const asop = () => {
    start(0)
    get(domainName)
      .then((contact) => {
        openRightBar(
          <ReapplyEditor
            domainName={domainName}
            contact={contact}
            reload={props.reload}
          />
        )
        stop()
      })
      .catch((err) => {
        console.log(err)
        stop()
      })
  }

  if (isApply) {
    return (
      <ButtonRounded name="Reapply" onClick={() => asop()} disabled={false} />
    )
  }

  return <></>
}

export default ReApplyTransfer
